(<template>
  <div class="force-logout">
    <tt-progress />
  </div>
</template>)

<script>
  export default {
    created() {
      this.$store.commit('TTAuthStore/logOut');
      this.$router.replace(this.$makeRoute({name: 'SignIn'}));
    },
    mounted() {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };
</script>

<style scoped>
  .force-logout {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>
